import React from 'react';

import { UqCustomerStory, UqCustomerProfile, UqTypography, UqLink, UqContainerV2, UqRequestDemoBanner, UqAppBarV2, UqLayoutV2, UqNavbarV2, UqFooterV2, UqPage, UqArticleQuote } from '@uq-components';
import Logo from '@uq-assets/logos/the-meet-group.svg';

import * as styles from './themeetgroup.module.scss';

export default function TheMeetGroupPage() {
  return (
    <UqPage
      config={{
        seo: {
          description: 'The Meet Group harnesses the voice of the customer to improve product quality with unitQ.',
          src: '/images/v2/customers/the-meet-group/the-meet-group-hero.png',
          title: 'The Meet Group Customer Story',
        },
      }}
    >
      <UqLayoutV2>
        <UqAppBarV2>
          <UqNavbarV2 />
        </UqAppBarV2>

        <UqCustomerStory>
          <UqCustomerStory.Header
            backgroundSrc="/images/v2/customers/the-meet-group/the-meet-group-hero.png"
            classes={{ background: styles.hero }}
          >
            <UqTypography as="h1">
              {'The Meet Group Harnesses the Voice of the Customer To Improve Product Quality With unitQ'}
            </UqTypography>
          </UqCustomerStory.Header>

          <UqCustomerStory.Content>
            <UqCustomerProfile logo={<Logo />}>
              <UqCustomerProfile.Section title="Product">Social Networking</UqCustomerProfile.Section>
              <UqCustomerProfile.Section title="Headquarters">New Hope, PA</UqCustomerProfile.Section>
              <UqCustomerProfile.Section title="Employees">200+</UqCustomerProfile.Section>
              <UqCustomerProfile.Section title="Quality Challenge">
                Lacked an AI-enabled tool to chronicle, parse and make sense of it&apos;s user feedback in multiple languages
                to generate product quality insights for roadmaps, product growth and bug fix prioritization.
              </UqCustomerProfile.Section>
              <UqCustomerProfile.Section title="Results">
                <UqTypography>
                  !-Centralized, automated, and simple-! tool now makes it easy to get early warning signs of bugs or
                  potential issues that must be resolved to improve the user experience.
                </UqTypography>
              </UqCustomerProfile.Section>
            </UqCustomerProfile>

            <UqCustomerStory.Article>

              <p>
                <span>The </span>
                <UqLink
                  className="article-link"
                  to="https://www.themeetgroup.com/"
                  external
                  hideArrow
                >
                  Meet Group
                </UqLink>
                <span>{' '} was built on a foundation of innovation, fun, and safety. Over the past decade, The Meet Group has built
                  a portfolio of mobile apps and services that span digital experiences from virtual coffee shops to friendship
                  finders, adventure planners, dating icebreakers, and more. Altogether,
                  the group&apos;s livestreaming solutions have
                  enticed their community to engage in 1 billion minutes of video per month.
                  The group&apos;s platform powers the creator economy —
                  and is a home where brands can monetize and engage in new ways.
                </span>              </p>

              <UqTypography as="h4">
                The Problem: !-No Developer Tool to Harness the Voice of the Customer-!
              </UqTypography>

              <p>
                As you can imagine, The Meet Group — the maker of MeetMe, Skout, GROWLr, Tagged —
                gets a ton of customer feedback on the Google Play Store, App Store, Reddit, social media and more.
                But until it found unitQ, the company didn&apos;t have an AI-enabled tool to chronicle, parse and make sense
                of all of its user feedback in multiple languages.
                This matters because user feedback is a tremendous asset. Customers are the one entity testing a company&apos;s
                product every day, for every update in every language and configuration,
                and on every platform and device. They
                are always on top of what is and what is not working. The Meet Group understood the importance and power
                of user feedback. But because they didn&apos;t have the tools required to properly analyze user feedback,
                this placed
                the company in a reactionary mode when it came to their product quality efforts.
                They also didn&apos;t have the tools
                to leverage the voice of the customer to produce product quality insights for roadmaps and product growth.
                In response, they sought out technology to tap into the voice of the customer to hasten their path to becoming
                a top provider of live streaming video and creator economy solutions for a number
                of the world&apos;s top social apps.
              </p>

              <UqTypography as="h4">
                The Solution: !-unitQ Provides a Single Source of Truth of User Feedback-!
              </UqTypography>

              <p>
                The company&apos;s biggest priority was to acquire a platform able to process multiple user feedback channels into
                a single collection, and alert to any increases in
                feedback areas to help identify and correct production bugs.
                That platform was unitQ. If aggregated correctly in a single tool,
                user feedback data can empower companies of any size to take a measured,
                data-driven approach to their product quality efforts, fix issues faster, and leverage
                insights into roadmaps and product growth.
              </p>

              <UqArticleQuote>
                Our QA team uses unitQ for early detection,
                quality reviews and to identify parts of the product that could be improved.
                We have set up numerous alerts to trigger if a production change leads to a user-facing bug.
              </UqArticleQuote>

              <span>says Michael Dillon, Senior Quality Assurance Analyst at The Meet Group.</span>

              <UqArticleQuote>Because of unitQ, we&apos;re now able to surface and fix issues that we were not aware of.</UqArticleQuote>

              <br />
              <br />
              <p>
                Dillon says that the QA team can easily find out the biggest complaints from users and
                then recommend to their product team how to improve and to prioritize fixes.
              </p>

              <UqArticleQuote>
                Being able to search through all of our feedback with unitQ helps us
                to find additional cases to aid in reproducing an issue that is identified, but has a small footprint
              </UqArticleQuote>

              <span>, Dillon says. </span>

              <UqArticleQuote>
                unitQ helps us to be able to respond rapidly to user feedback and
                keep The Meet Group functioning correctly for all our users.
              </UqArticleQuote>

              <br />
              <br />

              <UqTypography as="h4">
                The results: !-Happier Customers, Developers and The Meet Group Executives-!
              </UqTypography>

              <p>
                The company has been happy with unitQ, the onboarding process,
                the pace of feature additions, and unitQ&apos;s always-on support.
              </p>

              <span>There&apos;s plenty of reasons why companies embrace unitQ. Our customers can benefit from:</span>
              <ul>
                <li>Improved app store ratings.</li>
                <li>Bolstered developer KPIs.</li>
                <li>Generated new revenue streams.</li>
                <li>Reduced churn.</li>
                <li>Lowered support ticket volume.</li>
                <li>Decreased time to detect, fix issues.</li>
              </ul>

              <br />

              <UqArticleQuote>
                User feedback data supplied by unitQ is empowering The Meet Group to take a
                measured, data-driven approach to our product quality efforts,
                is helping us fix issues faster and is providing insights
                to shape our roadmaps and product growth,
              </UqArticleQuote>

              <span> says Geoff Cook, Co-Founder and CEO of the Meet Group. </span>

              <UqArticleQuote>
                Thanks to unitQ, we have a centralized and simple solution that acts
                as an automated early warning system that we can use to improve our user experience.
              </UqArticleQuote>

              <br />
              <br />

              <UqTypography as="h4">
                The unitQ future of The Meet Group: !-Impact Analysis-!
              </UqTypography>

              <span>unitQ recently released a new feature: </span>
              <UqLink
                className="article-link"
                // eslint-disable-next-line max-len
                to="https://www.unitq.com/blog/impact-analysis-surfaces-critical-product-quality-issues-determines-impact-of-fixing-them"
                external
                hideArrow
              >
                Impact Analysis
              </UqLink>.
              <p>
                {' '} This first-of-its-kind tool makes it even easier for unitQ customers to prioritize fixes and understand
                the impact of addressing and fixing product quality issues near and dear to their users.
              </p>

              <UqArticleQuote>
                We&apos;re really excited to begin using Impact Analysis to help
                The Meet Group develop products with quality top of mind,
              </UqArticleQuote>

              <span> Dillon says. </span>

              <UqArticleQuote>I can safely say that our products are better because of unitQ.</UqArticleQuote>
            </UqCustomerStory.Article>
          </UqCustomerStory.Content>
        </UqCustomerStory>

        <UqContainerV2>
          <UqRequestDemoBanner />
        </UqContainerV2>

        <UqFooterV2 />
      </UqLayoutV2>
    </UqPage>
  );
}
